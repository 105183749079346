<template>
  <div class="mobileSinglePower">
    <div class="imgBox">
      <img src="../../assets/mobile/product/singlePower.png" class="img" />
    </div>
    <div class="param">
      <div class="title">无线电源状态检测器</div>
      <div class="tit">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">BS4065</div>
      </div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">40×65mm</div>
      </div>
      <div class="text">
        <div class="left">额定输入:</div>
        <div class="right">200V~250VAC/50Hz</div>
      </div>
      <div class="text">
        <div class="left">额定功率:</div>
        <div class="right">1W</div>
      </div>
      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>

      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
      <div class="text">
        <div class="left">通信协议:</div>
        <div class="right">自定义LSSN2.0</div>
      </div>
      <div class="text">
        <div class="left">工作频段:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>
      <div class="text">
        <div class="left">待机功耗:</div>
        <div class="right">＜1W</div>
      </div>
      <div class="text">
        <div class="left">无线发射功率:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div
        class="txt"
      >无线电源状态检测器采用220V供电，能检测220V电源是否断开，并将检测结果实时上报给主机。内部的储能电路可保证检测器在断电后还能有足够的时间无线上报断电状态的数据包。电源须接在受插卡取电模块控制的电源线上，不可接在不断电电源上，否则将无法检测到客人的拔卡动作。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {}
};
</script>
<style scoped lang="less">
.mobileSinglePower {
  background-color: #fff;
  .imgBox {
    width: 100%;
    padding-top: 0.8rem;
    padding-bottom: 0.65rem;
    display: flex;
    justify-content: center;
    .img {
      width:4.03rem;
      height: 2.96rem;
    }
  }
  .param {
    text-align: left;
    margin: 0 0.3rem;
    padding-bottom: 0.29rem;
    border-bottom: 1px solid #333;
    .title {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #333;
    }
    .tit {
      margin-top: 0.17rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.6rem;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0;
      }
      .left {
        width: 1.6rem;
        text-align: right;
      }
      .right {
        margin-left: 0.59rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>